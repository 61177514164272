/**
 * @jsxRuntime classic
 * @jsx jsx
 */
import { type ReactNode, memo } from 'react';
// eslint-disable-next-line @atlaskit/ui-styling-standard/use-compiled -- Ignored via go/DSP-18766
import { jsx, css } from '@emotion/react';
// @ts-expect-error
import { LayoutRenderer } from 'pretty-proptypes';
import { Code } from '@atlaskit/code';
import Lozenge from '@atlaskit/lozenge';
import { Pressable, Inline, xcss } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';
import { G50, G500, P50, P500, N20, N300, R500, B50 } from '@atlaskit/theme/colors';
import { fontFallback } from '@atlaskit/theme/typography';
import { SectionLink, SimpleMarkdownRenderer } from '@af/design-system-docs-ui';
import { Text } from '@atlaskit/primitives';

const containerStyles = css({
	borderRadius: token('border.radius.200', '8px'),
	margin: `${token('space.400', '32px')} ${token(
		'space.negative.200',
		'-16px',
	)} ${token('space.0', '0px')}`,
	padding: `${token('space.100', '8px')} ${token('space.200', '16px')}`,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-selectors -- Ignored via go/DSP-18766
	':first-of-type:is(div)': {
		marginBlockStart: token('space.0', '0px'),
	},
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-selectors -- Ignored via go/DSP-18766
	':target': {
		animation: 'highlight 250ms 2.5s ease-out forwards',
		backgroundColor: token('color.background.accent.blue.subtlest', B50),
	},
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-selectors -- Ignored via go/DSP-18766
	'@keyframes highlight': {
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
		'0%': {
			backgroundColor: token('color.background.accent.blue.subtlest', B50),
		},
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
		'100%': {
			backgroundColor: 'transparent',
		},
	},
});

const monospaceFontStack = `'SFMono-Medium', 'SF Mono', 'Segoe UI Mono', 'Roboto Mono', 'Ubuntu Mono', Menlo, Consolas, Courier, monospace`;

const propTitleContainerStyles = xcss({
	borderBlockEndWidth: 'border.width',
	borderBlockEndStyle: 'solid',
	borderBlockEndColor: 'color.border',
	marginBlockEnd: 'space.050',
	paddingBlockEnd: 'space.100',
});

const baseCodeStyles = css({
	// to make precedence deterministic these styles have the extra pseudo class ... wouldn't be otherwise needed
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-selectors -- Ignored via go/DSP-18766
	'&:first-of-type:is(code)': {
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
		font: token('font.body', fontFallback.body.medium),
		fontFamily: token('font.family.code', monospaceFontStack),
		padding: `${token('space.050', '4px')} ${token('space.100', '8px')}`,
	},
});

const deprecatedStyles = css({ textDecoration: 'line-through' });

const tableStyles = css({
	width: '100%',
	borderCollapse: 'collapse',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	th: {
		textAlign: 'left',
		padding: `${token('space.050', '4px')} ${token(
			'space.200',
			'16px',
		)} ${token('space.050', '4px')} ${token('space.100', '8px')}`,
		whiteSpace: 'nowrap',
		verticalAlign: 'top',
	},
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	td: {
		padding: `${token('space.050', '4px')} ${token('space.050', '4px')} ${token(
			'space.100',
			'8px',
		)}`,
		width: '100%',
	},
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	tbody: {
		borderBlockEnd: 'none',
	},
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	code: {
		wordBreak: 'normal',
		overflowWrap: 'anywhere',
	},
});

const expanderStyles = xcss({
	borderRadius: 'border.radius',
	border: 0,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	font: token('font.body', fontFallback.body.medium),
	width: 'auto',
	marginBlock: 'space.025',
	marginInline: 'space.0',
	paddingBlock: 'space.0',
	paddingInline: 'space.025',
	color: 'color.text.subtle',
	backgroundColor: 'color.background.neutral',
});

const expandedHoveredStyles = xcss({
	backgroundColor: 'color.background.discovery.hovered',
});

const typeStyles = css({
	backgroundColor: token('color.background.discovery', P50),
	borderRadius: token('border.radius', '3px'),
	color: token('color.text.discovery', P500),
	display: 'inline-block',
	margin: `${token('space.025', '2px')} ${token('space.0', '0px')}`,
	padding: `${token('space.0', '0px')} ${token('space.025', '0.2em')}`,
});

const descriptionStyles = css({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors, @atlaskit/ui-styling-standard/no-unsafe-selectors -- Ignored via go/DSP-18766
	'p:first-of-type': {
		marginBlockStart: token('space.0', '0px'),
	},
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors, @atlaskit/ui-styling-standard/no-unsafe-selectors -- Ignored via go/DSP-18766
	'p:last-of-type': {
		marginBlockEnd: token('space.0', '0px'),
	},
});

const Type = (props: { children: ReactNode }) => <span css={typeStyles} {...props} />;

const StringType = (props: { children: ReactNode }) => (
	<span css={[typeStyles, stringType]} {...props} />
);
const Required = (props: { children: ReactNode }) => (
	<span
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/design-system/consistent-css-prop-usage -- Ignored via go/DSP-18766
		css={css({
			color: token('color.text.danger', R500),
		})}
	>
		{props.children}
	</span>
);

const Expander = ({
	isHovered,
	onMouseEnter,
	onMouseLeave,
	onClick,
	children,
}: {
	isHovered: boolean;
	onMouseEnter: () => void;
	onMouseLeave: () => void;
	onClick: () => void;
	children: ReactNode;
}) => (
	<Pressable
		onClick={onClick}
		xcss={[expanderStyles, isHovered && expandedHoveredStyles]}
		onMouseEnter={onMouseEnter}
		onMouseLeave={onMouseLeave}
	>
		{children}
	</Pressable>
);

const FunctionType = (props: { children: ReactNode }) => <span {...props} />;

const typeMeta = css({
	backgroundColor: token('color.background.neutral', N20),
	color: token('color.text.subtle', N300),
});

const TypeMeta = (props: { children: ReactNode }) => (
	<span css={[typeStyles, typeMeta]} {...props} />
);

const stringType = css({
	backgroundColor: token('color.background.success', G50),
	color: token('color.text.success', G500),
});

export const PropTable = ({
	defaultValue,
	deprecated,
	description,
	name,
	required,
	type,
	typeValue,
	componentDisplayName,
}: {
	name: string;
	required: boolean;
	deprecated: boolean;
	defaultValue: any;
	description: string;
	type: any;
	typeValue: any;
	componentDisplayName?: string;
}) => {
	const titleId =
		componentDisplayName && typeof componentDisplayName === 'string'
			? `${componentDisplayName}-${name}`
			: name;

	return (
		<div id={titleId} css={containerStyles}>
			<table css={tableStyles}>
				<caption
					// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/design-system/consistent-css-prop-usage -- Ignored via go/DSP-18766
					css={css({
						textAlign: 'left',
						margin: token('space.0', '0px'),
						fontSize: '1em',
					})}
				>
					<Inline xcss={propTitleContainerStyles} space="space.200" alignBlock="center">
						<SectionLink id={titleId} depth={4} heading={name}>
							<Code css={[baseCodeStyles, deprecated && deprecatedStyles]}>{name}</Code>
						</SectionLink>
						{required && <Lozenge appearance="removed">Required</Lozenge>}
						{deprecated && <Lozenge appearance="moved">Deprecated</Lozenge>}
					</Inline>
				</caption>
				<tbody>
					<tr>
						<th scope="row">Description</th>
						<td>
							<div css={descriptionStyles}>
								{description ? (
									<SimpleMarkdownRenderer>
										{/* Deprecated tags are already extracted into a lozenge above using pretty-prop-types */}
										{description.replace('@deprecated', '')}
									</SimpleMarkdownRenderer>
								) : (
									<Text color="color.text.subtle">
										<i>No description.</i>
									</Text>
								)}
							</div>
						</td>
					</tr>
					{defaultValue !== undefined && (
						<tr>
							<th scope="row">Default</th>
							<td>
								<Code>{defaultValue}</Code>
							</td>
						</tr>
					)}
					<tr>
						<th scope="row">Type</th>
						<td
							// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/design-system/consistent-css-prop-usage -- Ignored via go/DSP-18766
							css={css({
								display: 'flex',
								flexDirection: 'column',
							})}
						>
							<span>
								<Code>{type}</Code>
							</span>
							<span>{typeValue}</span>
						</td>
					</tr>
				</tbody>
			</table>
		</div>
	);
};

const PropsLayoutRenderer = memo((props) => (
	<LayoutRenderer
		requiredPropsFirst
		{...props}
		renderType={({ components, typeValue, ...type }: any) => (
			<PropTable
				{...type}
				typeValue={
					<components.PropType
						typeValue={typeValue}
						components={{
							...components,
							Type,
							TypeMeta,
							StringType,
							FunctionType,
							Required,
							Expander,
						}}
					/>
				}
			/>
		)}
	/>
));

export default PropsLayoutRenderer;
